function setTheme() {
  const theme = window.localStorage.theme;

  if (theme && theme !== "undefined") {
    console.log("using theme:", theme);
    document.body.setAttribute("data-theme", theme);
  } else {
    console.log("reseting theme");
    document.body.removeAttribute("data-theme");
  }
}

addEventListener("DOMContentLoaded", (event) => {
  console.log("restoring settings from local storage", setTheme);
  window.setTheme = setTheme;
  setTheme();
});
